/*global $*/
/*eslint no-undef: "error"*/

export default class Blocks {
    classBlocks = 'blocks'
    classBlock = 'blocks--block'
    classBlockHidden = 'blocks__block-hidden'
    classTab = 'blocks--tab'
    classTabActive = 'button-blue'
    classTabActiveNo = 'button-transparent-blue'
    classTabs = 'blocks__tabs'

    constructor () {
        let self = this

        $(`.${self.classTab}`).click(function () {
            let number = $(this).data('number')
            $(this).closest(`.${self.classTabs}`).find(`.${self.classTab}`).removeClass(self.classTabActive).addClass(self.classTabActiveNo)
            $(this).removeClass(self.classTabActiveNo).addClass(self.classTabActive)

            $(this).closest(`.${self.classBlocks}`).find(`.${self.classBlock}`).addClass(self.classBlockHidden)
            $(this).closest(`.${self.classBlocks}`).find(`.${self.classBlock}[data-number="${number}"]`).removeClass(self.classBlockHidden)
        })
    }
}
