<template lang="pug">
.form-offer
  .form-offer__block(:class="{'form-offer__block-hidden': !sendForm}")
    .button(
      @click="getNew()"
    )
      .button__name(v-html="formButtonCreate")

  .form__block(:class="{'form__block-visibility': sendForm}")
    .form-offer__fields
      .input-text.input-text-gray
        input.input-text__input(
          type="text"
          v-model="fieldCarrierName"
          :placeholder="placeholderFieldCarrierName"
        )
      .input-text.input-text-gray
        input.input-text__input(
          type="text"
          v-model="fieldContactName"
          :placeholder="placeholderFieldContactName"
        )
      .input-text.input-text-gray
        input.input-text__input(
          type="text"
          v-model="fieldDOT"
          :placeholder="placeholderFieldDOT"
        )
      .input-text.input-text-gray
        input.input-text__input(
          type="text"
          v-model="fieldMC"
          :placeholder="placeholderFieldMC"
        )
      .input-text.input-text-gray
        input.input-text__input(
          type="tel"
          v-model="fieldPhone"
          :placeholder="placeholderFieldPhone"
          v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
        )
      .input-text.input-text-gray
        input.input-text__input(
          type="email"
          v-model="fieldEmail"
          :placeholder="placeholderFieldEmail"
        )
    .form-offer__types
      .form-offer__types-title Trailer Types
      .form-offer__types-list
        .input-type
          input.input-type__input(type="checkbox" id="form-offer-type-1" v-model="fieldDryvan" :value="placeholderFieldCheckboxDryvan")
          label.input-type__label(for="form-offer-type-1" v-html="placeholderFieldCheckboxDryvan")
          input.input-type__field(type="text" :placeholder="placeholderFieldCheckboxRate" v-model="fieldDryvanValue")
        .input-type
          input.input-type__input(type="checkbox" id="form-offer-type-2" v-model="fieldStepdeck" :value="placeholderFieldCheckboxStepdeck")
          label.input-type__label(for="form-offer-type-2" v-html="placeholderFieldCheckboxStepdeck")
          input.input-type__field(type="text" :placeholder="placeholderFieldCheckboxRate" v-model="fieldStepdeckValue")
        .input-type
          input.input-type__input(type="checkbox" id="form-offer-type-3" v-model="fieldReefer" :value="placeholderFieldCheckboxReefer")
          label.input-type__label(for="form-offer-type-3" v-html="placeholderFieldCheckboxReefer")
          input.input-type__field(type="text" :placeholder="placeholderFieldCheckboxRate" v-model="fieldReeferValue")
        .input-type
          input.input-type__input(type="checkbox" id="form-offer-type-4" v-model="fieldConnestoga" :value="placeholderFieldCheckboxConnestoga")
          label.input-type__label(for="form-offer-type-4" v-html="placeholderFieldCheckboxConnestoga")
          input.input-type__field(type="text" :placeholder="placeholderFieldCheckboxRate" v-model="fieldConnestogaValue")
        .input-type
          input.input-type__input(type="checkbox" id="form-offer-type-5" v-model="fieldFlatbed" :value="placeholderFieldCheckboxFlatbed")
          label.input-type__label(for="form-offer-type-5" v-html="placeholderFieldCheckboxFlatbed")
          input.input-type__field(type="text" :placeholder="placeholderFieldCheckboxRate" v-model="fieldFlatbedValue")
        .input-type
          input.input-type__input(type="checkbox" id="form-offer-type-6" v-model="fieldContainer" :value="placeholderFieldCheckboxContainer")
          label.input-type__label(for="form-offer-type-6" v-html="placeholderFieldCheckboxContainer")
          input.input-type__field(type="text" :placeholder="placeholderFieldCheckboxRate" v-model="fieldContainerValue")
    .form-offer__button
      .button(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__name(
          v-html="formButtonName"
        )
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormOffer',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",
    "formFieldCarrierName",
    "formFieldContactName",
    "formFieldDot",
    "formFieldMc",
    "formFieldPhone",
    "formFieldEmail",
    "formFieldCheckboxRate",
    "formFieldCheckboxDryvan",
    "formFieldCheckboxStepdeck",
    "formFieldCheckboxReefer",
    "formFieldCheckboxConnestoga",
    "formFieldCheckboxFlatbed",
    "formFieldCheckboxContainer",
    "formButtonName",
    "formButtonCreate"
  ],

  data () {
    return {
      fieldCarrierName: '',
      fieldContactName: '',
      fieldDOT: '',
      fieldMC: '',
      fieldPhone: '',
      fieldEmail: '',

      fieldDryvan: '',
      fieldDryvanValue: '',
      fieldStepdeck: '',
      fieldStepdeckValue: '',
      fieldReefer: '',
      fieldReeferValue: '',
      fieldConnestoga: '',
      fieldConnestogaValue: '',
      fieldFlatbed: '',
      fieldFlatbedValue: '',
      fieldContainer: '',
      fieldContainerValue: '',

      files: '',
      sendForm: false,
      placeholderFieldCarrierName: this.formFieldCarrierName + ' *',
      placeholderFieldContactName: this.formFieldContactName + ' *',
      placeholderFieldDOT: this.formFieldDot + ' *',
      placeholderFieldMC: this.formFieldMc + ' *',
      placeholderFieldPhone: this.formFieldPhone + ' *',
      placeholderFieldEmail: this.formFieldEmail + ' *',

      placeholderFieldCheckboxRate: this.formFieldCheckboxRate,

      placeholderFieldCheckboxDryvan: this.formFieldCheckboxDryvan,
      placeholderFieldCheckboxStepdeck: this.formFieldCheckboxStepdeck,
      placeholderFieldCheckboxReefer: this.formFieldCheckboxReefer,
      placeholderFieldCheckboxConnestoga: this.formFieldCheckboxConnestoga,
      placeholderFieldCheckboxFlatbed: this.formFieldCheckboxFlatbed,
      placeholderFieldCheckboxContainer: this.formFieldCheckboxContainer
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm
        $('.title--form-offer').toggleClass('title-hidden')
        $('.title--form-offer-send').toggleClass('title-hidden')

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldCarrierName,
              value: this.fieldCarrierName
            },
            {
              title: this.formFieldContactName,
              value: this.fieldContactName
            },
            {
              title: this.formFieldDot,
              value: this.fieldDOT
            },
            {
              title: this.formFieldMc,
              value: this.fieldMC
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },

            {
              title: this.placeholderFieldCheckboxDryvan,
              value: (this.fieldDryvan && this.fieldDryvanValue) ? this.fieldDryvanValue : ''
            },
            {
              title: this.placeholderFieldCheckboxStepdeck,
              value: (this.fieldStepdeck && this.fieldStepdeckValue) ? this.fieldStepdeckValue : ''
            },
            {
              title: this.placeholderFieldCheckboxReefer,
              value: (this.fieldReefer && this.fieldReeferValue) ? this.fieldReeferValue : ''
            },
            {
              title: this.placeholderFieldCheckboxConnestoga,
              value: (this.fieldConnestoga && this.fieldConnestogaValue) ? this.fieldConnestogaValue : ''
            },
            {
              title: this.placeholderFieldCheckboxFlatbed,
              value: (this.fieldFlatbed && this.fieldFlatbedValue) ? this.fieldFlatbedValue : ''
            },
            {
              title: this.placeholderFieldCheckboxContainer,
              value: (this.fieldContainer && this.fieldContainerValue) ? this.fieldContainerValue : ''
            }
          ],
          files: this.files
        })

        this.fieldCarrierName = this.fieldContactName = this.fieldDOT = this.fieldMC = this.fieldPhone = this.fieldEmail = ''
        this.fieldDryvan = this.fieldStepdeck = this.fieldReefer = this.fieldConnestoga = this.fieldFlatbed = this.fieldContainer = ''
        this.fieldDryvanValue = this.fieldStepdeckValue = this.fieldReeferValue = this.fieldConnestogaValue = this.fieldFlatbedValue = this.fieldContainerValue = ''
      }
    },

    getNew () {
      this.sendForm = !this.sendForm
      $('.title--form-offer').toggleClass('title-hidden')
      $('.title--form-offer-send').toggleClass('title-hidden')
    }
  },

  validations () {
    return {
      fieldCarrierName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40)
      },

      fieldContactName: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40)
      },

      fieldDOT: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40)
      },

      fieldMC: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(40)
      },

      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },

      fieldEmail: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(40),
        email
      }
    }
  }
}
</script>
