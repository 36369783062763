<template lang="pug">
.form.form-question
  .form__block(:class="{'form__block-hidden': !sendForm}")
    .button(
      @click="getNew()"
    )
      .button__name(v-html="formButtonCreate")

  .form__block(:class="{'form__block-visibility': sendForm}")
    .form__fields
      .form__field
        .input-text
          input.input-text__input(
            type="text"
            v-model="fieldName"
            :placeholder="placeholderFieldName"
          )
      .form__field
        .input-text
          input.input-text__input(
            type="email"
            v-model="fieldEmail"
            :placeholder="placeholderFieldEmail"
          )
      .form__field
        .input-text
          input.input-text__input(
            type="tel"
            v-model="fieldPhone"
            :placeholder="placeholderFieldPhone"
            v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
          )
      .form__field
        .input-textarea
          textarea.input-textarea__input(
            v-model="fieldText"
            :placeholder="placeholderFieldText"
          )
    .form__button
      .button(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__name(
          v-html="formButtonName"
        )
</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppForm',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",
    "formFieldEmail",
    "formFieldPhone",
    "formFieldName",
    "formFieldText",
    "formButtonName",
    "formButtonCreate"
  ],

  data () {
    return {
      fieldPhone: '',
      fieldEmail: '',
      fieldName: '',
      fieldText: '',
      files: '',
      sendForm: false,
      placeholderFieldEmail: this.formFieldEmail + ' *',
      placeholderFieldName: this.formFieldName + ' *',
      placeholderFieldPhone: this.formFieldPhone + ' *',
      placeholderFieldText: this.formFieldText + ' *'
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm
        $('.title--form-question').toggleClass('title-hidden')
        $('.title--form-question-send').toggleClass('title-hidden')

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldEmail = this.fieldPhone = this.fieldText = ''
      }
    },

    getNew () {
      this.sendForm = !this.sendForm
      $('.title--form-question').toggleClass('title-hidden')
      $('.title--form-question-send').toggleClass('title-hidden')
    }
  },

  validations () {
    return {
      fieldEmail: {
        required,
        email
      },

      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },

      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40)
      },

      fieldText: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(300)
      }
    }
  }
}
</script>
