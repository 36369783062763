<template lang="pug">
.form-calc
  .form-calc__block(:class="{'form-calc__block-hidden': !sendForm}")
    .button(
      @click="getNew()"
    )
      .button__name(v-html="formButtonCreate")

  .form-calc__block(:class="{'form-calc__block-visibility': sendForm}")
    .form-calc__elements
      .form-calc__element
        .form-calc__title(v-html="formFieldsTitle1")
        .form-calc__list
          .form-calc__item.form-calc__item-1
            label.input-text
              .input-text__label(v-html="placeholderFieldName")
              input.input-text__input(
                type="text"
                placeholder=""
                v-model="fieldName"
              )
          .form-calc__item.form-calc__item-3
            label.input-text
              .input-text__label(v-html="placeholderFieldPhone")
              input.input-text__input(
                type="tel"
                placeholder=""
                v-model="fieldPhone"
                v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
              )
          .form-calc__item.form-calc__item-5
            label.input-text
              .input-text__label(v-html="placeholderFieldCompanyName")
              input.input-text__input(
                type="text"
                placeholder=""
                v-model="fieldCompanyName"
              )
          .form-calc__item.form-calc__item-2
            label.input-text
              .input-text__label(v-html="placeholderFieldLastName")
              input.input-text__input(
                type="text"
                placeholder=""
                v-model="fieldLastName"
              )
          .form-calc__item.form-calc__item-4
            label.input-text
              .input-text__label(v-html="placeholderFieldEmail")
              input.input-text__input(
                type="email"
                placeholder=""
                v-model="fieldEmail"
              )
      .form-calc__element
        .form-calc__title(v-html="formFieldsTitle2")
        .form-calc__list
          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldPickupLocation" )
              input.input-text__input(
                type="text"
                placeholder="City / State or ZIP"
                v-model="fieldPickupLocation"
              )
            .form-calc__checkbox
              label.input-checkbox
                input.input-checkbox__input(type="checkbox" value="Need Pickup ASAP" v-model="fieldNeedPickup" :value="placeholderFieldNeedPickup")
                span.input-checkbox__label.input-checkbox__label-bold.input-checkbox__label-blue(v-html="placeholderFieldNeedPickup")

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldDesiredPickup")
              input.input-text__input(
                type="text"
                placeholder=""
                v-model="fieldDesiredPickup"
              )
              svg.input-text__icon
                use(xlink:href="#contact-calendar")

          .form-calc__item.form-calc__item-hidden

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldDeliveryLocation")
              input.input-text__input(
                type="text"
                placeholder="City / State or ZIP"
                v-model="fieldDeliveryLocation"
              )
              .form-calc__checkbox
                label.input-checkbox
                  input.input-checkbox__input(type="checkbox" value="Need Pickup ASAP" v-model="fieldNeedPickup2" :value="placeholderFieldNeedPickup")
                  span.input-checkbox__label.input-checkbox__label-bold.input-checkbox__label-blue(v-html="placeholderFieldNeedPickup")

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldDesiredPickup")
              input.input-text__input(
                type="text"
                v-model="fieldDesiredPickup2"
              )
              svg.input-text__icon
                use(xlink:href="#contact-calendar")

          .form-calc__item.form-calc__item-hidden
      .form-calc__element
        .form-calc__title(v-html="formFieldsTitle3")
        .form-calc__list
          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldNumberPieces")
              input.input-text__input(
                type="text"
                placeholder=""
                v-model="fieldNumberPieces"
              )

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldTotal")
              input.input-text__input(
                type="text"
                placeholder=""
                v-model="fieldTotal"
              )

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldCommodity")
              input.input-text__input(
                type="text"
                placeholder="What are we shipping?"
                v-model="fieldCommodity"
              )

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldDimensions")
              input.input-text__input(
                type="text"
                placeholder="Length x Width x Height"
                v-model="fieldDimensions"
              )

          .form-calc__item.form-calc__item-textarea
            .input-textarea
              .input-textarea__label(v-html="placeholderFieldNotes")
              textarea.input-textarea__input(
                v-model="fieldNotes"
              )

          .form-calc__item
            label.input-text
              .input-text__label(v-html="placeholderFieldPackageType")
              input.input-text__input(
                type="text"
                placeholder="Please Select"
                v-model="fieldPackageType"
              )
    .form-calc__consent
      label.input-checkbox
        input.input-checkbox__input(type="checkbox" v-model="consent" value="consent" checked="checked")
        span.input-checkbox__label(v-html="formConsent")
    .form-calc__button
      .button(
        :class="{'button-disable': v$.$invalid}"
        @click="getSend()"
      )
        .button__name(
          v-html="formButtonName"
        )

</template>

<script>
/*global $*/
/*eslint no-undef: "error"*/

import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormCalc',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
      'formSettingTitle',
      'formSettingSubject',

      'formFieldsTitle1',
      'formFieldName',
      'formFieldPhone',
      'formFieldCompanyName',
      'formFieldLastName',
      'formFieldEmail',

      'formFieldsTitle2',
      'formFieldPickupLocation',
      'formFieldDesiredPickup',
      'formFieldNeedPickup',
      'formFieldDeliveryLocation',

      'formFieldsTitle3',
      'formFieldNumberPieces',
      'formFieldTotal',
      'formFieldCommodity',
      'formFieldDimensions',
      'formFieldNotes',
      'formFieldPackageType',

      'formButtonName',
      'formButtonCreate',

      'formConsent'
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldCompanyName: '',
      fieldLastName: '',
      fieldEmail: '',
      placeholderFieldName: this.formFieldName + ' <span>*</span>',
      placeholderFieldPhone: this.formFieldPhone + ' <span>*</span>',
      placeholderFieldCompanyName: this.formFieldCompanyName + ' <span>*</span>',
      placeholderFieldLastName: this.formFieldLastName + ' <span>*</span>',
      placeholderFieldEmail: this.formFieldEmail + ' <span>*</span>',

      fieldPickupLocation: '',
      fieldNeedPickup: '',
      fieldDesiredPickup: '',
      fieldDeliveryLocation: '',
      fieldNeedPickup2: '',
      fieldDesiredPickup2: '',
      placeholderFieldPickupLocation: this.formFieldPickupLocation + ' <span>*</span>',
      placeholderFieldDesiredPickup: this.formFieldDesiredPickup + ' <span>*</span>',
      placeholderFieldNeedPickup: this.formFieldNeedPickup,
      placeholderFieldDeliveryLocation: this.formFieldDeliveryLocation + ' <span>*</span>',

      fieldNumberPieces: '',
      fieldTotal: '',
      fieldCommodity: '',
      fieldDimensions: '',
      fieldNotes: '',
      fieldPackageType: '',
      placeholderFieldNumberPieces: this.formFieldNumberPieces + ' <span>*</span>',
      placeholderFieldTotal: this.formFieldTotal + ' <span>*</span>',
      placeholderFieldCommodity: this.formFieldCommodity + ' <span>*</span>',
      placeholderFieldDimensions: this.formFieldDimensions + ' <span>*</span>',
      placeholderFieldNotes: this.formFieldNotes + ' <span>*</span>',
      placeholderFieldPackageType: this.formFieldPackageType + ' <span>*</span>',

      files: '',
      sendForm: false,
      consent: ['consent']
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm
        $('.title--form-calc').toggleClass('title-hidden')
        $('.title--form-calc-send').toggleClass('title-hidden')

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldCompanyName,
              value: this.fieldCompanyName
            },
            {
              title: this.formFieldLastName,
              value: this.fieldLastName
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },

            {
              title: this.formFieldPickupLocation,
              value: (this.fieldNeedPickup) ? this.fieldPickupLocation + ' (' + this.formFieldNeedPickup + ')' : this.fieldPickupLocation
            },
            {
              title: this.formFieldDesiredPickup,
              value: this.fieldDesiredPickup
            },
            {
              title: this.formFieldDeliveryLocation,
              value: (this.fieldNeedPickup2) ? this.fieldDeliveryLocation + ' (' + this.formFieldNeedPickup + ')' : this.fieldDeliveryLocation
            },
            {
              title: this.formFieldDesiredPickup,
              value: this.fieldDesiredPickup2
            },

            {
              title: this.formFieldNumberPieces,
              value: this.fieldNumberPieces
            },
            {
              title: this.formFieldTotal,
              value: this.fieldTotal
            },
            {
              title: this.formFieldCommodity,
              value: this.fieldCommodity
            },
            {
              title: this.formFieldDimensions,
              value: this.fieldDimensions
            },
            {
              title: this.formFieldNotes,
              value: this.fieldNotes
            },
            {
              title: this.formFieldPackageType,
              value: this.fieldPackageType
            },
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldCompanyName = this.fieldLastName = this.fieldEmail = ''
        this.fieldPickupLocation = this.fieldDesiredPickup = this.fieldDeliveryLocation = this.fieldDesiredPickup2 = ''
        this.fieldNumberPieces = this.fieldTotal = this.fieldCommodity = this.fieldDimensions = this.fieldNotes = this.fieldPackageType = ''
      }
    },

    getNew () {
      this.sendForm = !this.sendForm
      $('.title--form-calc').toggleClass('title-hidden')
      $('.title--form-calc-send').toggleClass('title-hidden')
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3)
      },

      fieldPhone: {
        required,
        minLength: minLength(17),
        maxLength: maxLength(17)
      },

      fieldCompanyName: {
        required,
        minLength: minLength(3)
      },

      fieldLastName: {
        required,
        minLength: minLength(3)
      },

      fieldEmail: {
        required,
        minLength: minLength(5),
        email
      },

      fieldPickupLocation: {
        required,
      },

      fieldDesiredPickup: {
        required,
      },

      fieldDeliveryLocation: {
        required,
      },

      fieldDesiredPickup2: {
        required,
      },

      fieldNumberPieces: {
        required,
      },

      fieldTotal: {
        required,
      },

      fieldCommodity: {
        required,
      },

      fieldDimensions: {
        required,
      },

      fieldNotes: {
        required,
      },

      fieldPackageType: {
        required,
      },

      consent: {
        required
      }
    }
  }
}
</script>
