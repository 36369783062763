/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Blocks from '@/assets/js/Blocks/Blocks'
import HeaderMobile from '@/assets/js/Header/HeaderMobile/HeaderMobile'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'
import AppForm from '@/components/Form/Form/AppForm.vue'
import AppFormOffer from '@/components/Form/FormOffer/AppFormOffer.vue'
import AppFormCalc from '@/components/Form/FormCalc/AppFormCalc.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-subscribe]')
    new SwiperSlider()
    new Blocks()
    new HeaderMobile()
    new VideoImg()

    if(document.getElementById('block--form')!= null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form')
    }

    if(document.getElementById('block--form-offer')!= null) {
        createApp({})
            .component('block-form-offer', AppFormOffer)
            .use(Maska)
            .use(form)
            .mount('#block--form-offer')
    }

    if(document.getElementById('block--form-calc')!= null) {
        createApp({})
            .component('block-form-calc', AppFormCalc)
            .use(Maska)
            .use(form)
            .mount('#block--form-calc')
    }
})
